import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../services/httpService';
import { apiUrls } from '../../constants/apiUrls';
import { authHeader } from '../../constants/authenticationHeader';
import errorhandler from '../../services/errorhandler';

// get Application

export const getApplication = createAsyncThunk(
  'products/getApplication',
  async (data = {}) => {
    data.path = apiUrls.getApplication();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

// get SubCategories

export const getSubCategories = createAsyncThunk(
  'products/getSubCategories',
  async (data = {}) => {
    data.path = apiUrls.getSubCategories();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

// get Models

export const getModels = createAsyncThunk(
  'products/getModels',
  async (data = {}) => {
    data.path = apiUrls.getModels();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

// get Products

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (data = {}) => {
    const getProductsData = {};
    getProductsData.path = apiUrls.getProducts(data);
    const response = await APIs.fetch(getProductsData);
    return response.data;
  }
);

// get searched Combined-Products

export const getCombinedProductsSearch = createAsyncThunk(
  'products/getProducts',
  async (data = {}) => {
    const getProductsData = {};
    getProductsData.path = apiUrls.getCombinedProductsSearch(data);
    const response = await APIs.fetch(getProductsData);
    return response.data;
  }
);

// Add components

export const addComponents = createAsyncThunk(
  'products/addComponents',
  async (data = {}, { rejectWithValue }) => {
    const addComponentData = {};
    addComponentData.data = data;
    addComponentData.path = apiUrls.addComponents();
    addComponentData.csrf = authHeader();

    try {
      const response = await APIs.post(addComponentData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// get Equipment

export const getEquipment = createAsyncThunk(
  'products/getEquipment',
  async (data = {}) => {
    data.path = apiUrls.getEquipment();
    const response = await APIs.fetch(data);
    return response.data;
  }
);

// add models

export const addModels = createAsyncThunk(
  'products/addModels',
  async (data, { rejectWithValue }) => {
    const addModelData = {};
    addModelData.data = data;
    addModelData.csrf = authHeader();
    addModelData.path = apiUrls.addModels();

    try {
      const response = await APIs.post(addModelData);

      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// delete models

export const deleteModel = createAsyncThunk(
  'products/deletModel',
  async (data, { rejectWithValue }) => {
    const deleteModelData = {};
    deleteModelData.data = data.data;
    deleteModelData.csrf = authHeader();
    deleteModelData.path = apiUrls.deleteModel(data.data);

    try {
      const response = await APIs.dell(deleteModelData);
      return {
        response: response.data,
        products: data.products,
        productIndex: data.productIndex,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// add models

export const deleteComponent = createAsyncThunk(
  'products/deleteComponent',
  async (data) => {
    const deleteComponentData = {};
    deleteComponentData.data = data.data;
    deleteComponentData.csrf = authHeader();
    deleteComponentData.path = apiUrls.deleteComponent(data.data);
    const response = await APIs.dell(deleteComponentData);
    return {
      response: response.data,
      products: data.products,
      productIndex: data.productIndex,
    };
  }
);

// patch Model

export const patchModel = createAsyncThunk(
  'products/patchModel',
  async (data, { rejectWithValue }) => {
    const patchModelData = {};
    patchModelData.data = data.model;
    patchModelData.csrf = authHeader();
    patchModelData.path = apiUrls.patchModel(data.id);

    try {
      const response = await APIs.patch(patchModelData);
      return {
        response: response.data,
        productList: data.productList,
        index: data.index,
        model: data.model,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// patch Component

export const patchComponent = createAsyncThunk(
  'products/patchComponent',
  async (data, { rejectWithValue }) => {
    const patchComponentData = {};
    patchComponentData.data = data.component;
    patchComponentData.csrf = authHeader();
    patchComponentData.path = apiUrls.patchComponent(data.id);

    try {
      const response = await APIs.patch(patchComponentData);

      return {
        response: response.data,
        productList: data.productList,
        index: data.index,
        component: data.component,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// ///// Combine Products Actions Creator ////////

// Action for adding combined products

export const addCombinedProduct = createAsyncThunk(
  'products/addCombinedProduct',
  async (data, { rejectWithValue }) => {
    const addProductData = {};
    addProductData.data = data;
    addProductData.csrf = authHeader();
    addProductData.path = apiUrls.addCombinedProducts(); // Your API endpoint

    try {
      const response = await APIs.post(addProductData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// Action for updating combined products

export const patchCombinedProduct = createAsyncThunk(
  'products/patchCombinedProduct',
  async (data, { rejectWithValue }) => {
    console.log('patchData', data);
    const patchProductData = {};
    patchProductData.data = data.data;
    patchProductData.csrf = authHeader(); // If you're using CSRF tokens
    patchProductData.path = apiUrls.patchCombinedProducts(data.id); // Your API endpoint for patching
    console.log('patchProducts', patchProductData);

    try {
      const response = await APIs.patch(patchProductData);
      return {
        response: response.data,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// to get a single combined-product for edit mode

export const getCombinedProduct = createAsyncThunk(
  'products/getCombinedProduct',
  async (data) => {
    console.log('combineddata', data);
    const getCombinedProductData = {};
    getCombinedProductData.data = data;
    getCombinedProductData.csrf = authHeader();
    getCombinedProductData.path = apiUrls.getCombinedProduct(data);
    console.log('combinedProducts', getCombinedProductData);

    try {
      const response = await APIs.fetch(getCombinedProductData);
      console.log('erersponse', response);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// get Combined-Products fetching products

export const getCombinedProducts = createAsyncThunk(
  'products/getCombinedProducts',
  async (data = {}) => {
    const getProductsData = {};
    getProductsData.path = apiUrls.getCombinedProducts(data);

    try {
      const response = await APIs.fetch(getProductsData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// delete Combined-products

export const deleteCombinedProducts = createAsyncThunk(
  'products/deletCombinedProducts',
  async (data, { rejectWithValue }) => {
    const deleteProductData = {};
    deleteProductData.data = data.data;
    deleteProductData.csrf = authHeader();
    deleteProductData.path = apiUrls.deleteCombinedProducts(data.data);

    try {
      const response = await APIs.dell(deleteProductData);
      return {
        response: response.data,
        products: data.products,
        productIndex: data.productIndex,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

export const removeProduct = createAction('products/removeProduct');

// add Images localy

export const addImages = createAction(
  'products/addImgaes',
  function prepare(data) {
    return {
      payload: data,
    };
  }
);

// control dashboard drawer opening

export const drawerHandler = createAction('products/drawer');
// Reset addproductQuanitty in inventory response

export const resetInventoryProductPatch = createAction(
  'products/editProductReset'
);
// Reset product data

export const resetProductData = createAction('products/productReset');
