/* eslint-disable import/no-unused-modules */
import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PublicLayout } from './layouts/publicLayout';
import { routesPath } from './constants/routesPath';
import { CoreLayout } from './layouts/coreLayout';
import { AdminNavbar } from './components/header/adminNavbar';
import { preloadRoutes, getAllowedRoutes } from './utils/allowedRoutes';
import { PrivateRoute } from './routes/privateRoutes';
import { preloadComponent } from './utils/preloadComponent.js';

// Lazy imports
const SignInContainer = lazy(
  () => import('./containers/signInContainer/SignInContainer')
);
const UserConfirmationContainer = lazy(
  () => import('./containers/signInContainer/UserConfirmationContainer')
);
const SignupContainer = lazy(
  () => import('./containers/signupContainer/SignUpContainer')
);
const EmailVerificationFormContainer = lazy(
  () =>
    import(
      './containers/emailVerificationFormContainer/EmailVerificationFormContainer'
    )
);
const VerifyEmailContainer = lazy(
  () => import('./containers/verifyEmailContainer/VerifyEmailContainer')
);
const ResetPasswordContainer = lazy(
  () => import('./containers/resetPasswordContainer/ResetPasswordContainer')
);

export function Router() {
  useEffect(() => {
    // Preload other components after the initial page load
    preloadComponent(UserConfirmationContainer);
    preloadComponent(SignupContainer);
    preloadComponent(EmailVerificationFormContainer);
    preloadComponent(VerifyEmailContainer);
    preloadComponent(ResetPasswordContainer);
    preloadRoutes(getAllowedRoutes());
    // ... preload any other components as needed
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-left"
        hideProgressBar
        style={{ opacity: '0.8' }}
      />
      <AdminNavbar />

      <Suspense fallback={<div>Loading...</div>}>
        <PublicLayout>
          <Switch>
            {/* Public Routes */}
            <Route exact path={routesPath.login} component={SignInContainer} />
            <Route
              exact
              path={routesPath.userType}
              component={UserConfirmationContainer}
            />
            <Route
              exact
              path={routesPath.register}
              component={SignupContainer}
            />
            <Route
              exact
              path={routesPath.emailVerification}
              component={EmailVerificationFormContainer}
            />
            <Route
              exact
              path={routesPath.verifyEmail}
              component={VerifyEmailContainer}
            />
            <Route
              exact
              path={routesPath.reset}
              component={ResetPasswordContainer}
            />
          </Switch>
        </PublicLayout>
        <CoreLayout>
          <Switch>
            {/* Private Routes */}
            {getAllowedRoutes().map((route) => (
              <PrivateRoute
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </CoreLayout>
      </Suspense>
    </>
  );
}
