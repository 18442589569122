import React from 'react';
export const QuotesIcon = () => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4258 0.230469H4.42934C3.87725 0.230469 3.42969 0.67803 3.42969 1.23012C3.42969 1.7822 3.87725 2.22977 4.42934 2.22977H14.4258C14.9779 2.22977 15.4255 1.7822 15.4255 1.23012C15.4255 0.67803 14.9779 0.230469 14.4258 0.230469Z"
      // fill="#13214A"
    />
    <path
      d="M2.43068 1.22981C2.42955 0.965126 2.3235 0.711684 2.13578 0.525062C1.74589 0.137479 1.11617 0.137479 0.726277 0.525062C0.538531 0.711684 0.432474 0.965126 0.431381 1.22981C0.423915 1.29457 0.423915 1.35999 0.431381 1.42475C0.442689 1.48988 0.46112 1.55358 0.486362 1.61468C0.513196 1.67388 0.544966 1.73073 0.581328 1.78462C0.617222 1.84066 0.659113 1.89261 0.706284 1.93956C0.751768 1.98486 0.802032 2.0251 0.856232 2.05952C0.908869 2.09785 0.965943 2.12975 1.02617 2.15449C1.09243 2.18885 1.16306 2.21409 1.2361 2.22946C1.30086 2.23671 1.36627 2.23671 1.43103 2.22946C1.695 2.22968 1.94835 2.12547 2.13578 1.93956C2.18295 1.89261 2.22484 1.84066 2.26074 1.78462C2.2971 1.73073 2.32887 1.67388 2.3557 1.61468C2.3876 1.55433 2.41275 1.49063 2.43068 1.42475C2.43814 1.35999 2.43814 1.29457 2.43068 1.22981Z"
      // fill="#13214A"
    />
    <path
      d="M2.43094 5.72981C2.43837 5.66506 2.43837 5.59964 2.43094 5.53488C2.41366 5.47037 2.38848 5.40827 2.35596 5.34995C2.33022 5.28863 2.29839 5.23002 2.261 5.17501C2.22635 5.11959 2.18431 5.06914 2.13604 5.02506C1.74615 4.63748 1.11643 4.63748 0.726537 5.02506C0.53879 5.21168 0.432734 5.46513 0.431641 5.72981C0.433577 5.86155 0.458975 5.99185 0.506614 6.11468C0.531668 6.17381 0.561782 6.23067 0.596583 6.28462C0.634601 6.33894 0.678148 6.38921 0.726537 6.43457C0.770709 6.48274 0.821129 6.52478 0.876484 6.55952C0.929122 6.59788 0.986164 6.62978 1.04642 6.65449C1.10737 6.68014 1.17113 6.6986 1.23636 6.70947C1.3003 6.72381 1.36575 6.73052 1.43129 6.72946C1.49605 6.73693 1.56146 6.73693 1.62622 6.72946C1.68982 6.71856 1.75193 6.7001 1.81116 6.67448C1.87304 6.64993 1.93177 6.61803 1.98609 6.57951C2.04145 6.54478 2.09187 6.50273 2.13604 6.45456C2.18421 6.41039 2.22626 6.35997 2.261 6.30461C2.29942 6.252 2.33132 6.19496 2.35596 6.13467C2.39011 6.06832 2.41532 5.99772 2.43094 5.92474C2.43862 5.85999 2.43862 5.79457 2.43094 5.72981Z"
      // fill="#13214A"
    />
    <path
      d="M2.431 10.2259C2.4384 10.1612 2.4384 10.0957 2.431 10.031C2.41372 9.96488 2.38855 9.90112 2.35603 9.84104C2.32919 9.78185 2.29742 9.72499 2.26106 9.6711C2.22632 9.61575 2.18427 9.56533 2.1361 9.52116C1.74621 9.13357 1.11649 9.13357 0.726598 9.52116C0.678428 9.56533 0.63638 9.61575 0.601642 9.6711C0.56528 9.72499 0.53351 9.78185 0.506676 9.84104C0.480747 9.9019 0.462285 9.96569 0.451695 10.031C0.437606 10.095 0.430921 10.1604 0.431702 10.2259C0.432827 10.4906 0.538883 10.744 0.726598 10.9307C0.77077 10.9788 0.82119 11.0209 0.876546 11.0556C0.929183 11.094 0.986226 11.1259 1.04649 11.1506C1.10743 11.1762 1.17119 11.1947 1.23642 11.2056C1.30037 11.2199 1.36581 11.2266 1.43135 11.2256C1.49611 11.233 1.56152 11.233 1.62628 11.2256C1.68988 11.2147 1.75199 11.1962 1.81122 11.1706C1.8731 11.146 1.93183 11.1141 1.98616 11.0756C2.04151 11.0409 2.09193 10.9988 2.1361 10.9507C2.18427 10.9065 2.22632 10.8561 2.26106 10.8007C2.29951 10.7481 2.33141 10.6911 2.35603 10.6308C2.39014 10.5644 2.41535 10.4938 2.431 10.4208C2.43868 10.3561 2.43868 10.2907 2.431 10.2259Z"
      // fill="#13214A"
    />
    <path
      d="M15.4255 4.73047H4.42934C3.87725 4.73047 3.42969 5.17803 3.42969 5.73012C3.42969 6.2822 3.87725 6.72976 4.42934 6.72976H15.4255C15.9776 6.72976 16.4251 6.2822 16.4251 5.73012C16.4251 5.17803 15.9776 4.73047 15.4255 4.73047Z"
      // fill="#13214A"
    />
    <path
      d="M10.9271 9.22656H4.42934C3.87725 9.22656 3.42969 9.67412 3.42969 10.2262C3.42969 10.7783 3.87725 11.2259 4.42934 11.2259H10.9271C11.4791 11.2259 11.9267 10.7783 11.9267 10.2262C11.9267 9.67412 11.4791 9.22656 10.9271 9.22656Z"
      // fill="#13214A"
    />
  </svg>
);
