const tokenKey = 'token';
const roleKey = 'role';
const userEmailKey = 'userEmail';
const userIdKey = 'userId';
const tenantStatusKey = 'tenantStatus';
const tenantIdKey = 'tenantId';
const tenantDomainKey = 'tenantDomain';
const tenantSubscriptionStatus = 'tenantSubscriptionStatus';
const clearJWTToken = () => {
  localStorage.removeItem(tokenKey);
};

const setJWTToken = (token, userEmail, userId, role) => {
  localStorage.setItem(tokenKey, token);
  localStorage.setItem(userEmailKey, userEmail);
  localStorage.setItem(userIdKey, userId);
  localStorage.setItem(roleKey, role);
};

const setTenantInfo = (
  tenantStatus,
  tenantId,
  tenantDomain,
  tenantSubStatus
) => {
  localStorage.setItem(tenantStatusKey, tenantStatus);
  localStorage.setItem(tenantIdKey, tenantId);
  localStorage.setItem(tenantDomainKey, tenantDomain);
  localStorage.setItem(tenantSubscriptionStatus, tenantSubStatus);
};

const clearStorage = () => {
  localStorage.clear();
};

const getJWTToken = () => {
  return localStorage.getItem(tokenKey);
};

const getUserRole = () => {
  return localStorage.getItem(roleKey);
};

const getUserId = () => {
  return getUserRole() === 'ADMIN' ? 1 : localStorage.getItem(userIdKey);
};

const getTenantId = () => {
  return localStorage.getItem(tenantIdKey);
};

const getUserEmail = () => {
  return localStorage.getItem(userEmailKey);
};
const getTenantDomain = () => {
  return localStorage.getItem(tenantDomainKey);
};

const getTenantStatus = () => {
  return localStorage.getItem(tenantStatusKey);
};

const getTenantSubscriptionStatus = () => {
  return localStorage.getItem(tenantSubscriptionStatus);
};

export default {
  clearStorage,
  setJWTToken,
  clearJWTToken,
  getJWTToken,
  getUserRole,
  getUserId,
  getUserEmail,
  setTenantInfo,
  getTenantDomain,
  getTenantId,
  getTenantStatus,
  getTenantSubscriptionStatus,
};
