import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls } from '../../constants/apiUrls';
import APIs from '../../services/httpService';
import { authHeader } from '../../constants/authenticationHeader';

// /////////////////////// Store detail apis ////////////////////////////

// to Post store details
export const addStoreDetail = createAsyncThunk(
  'storeOverview/addStoreDetails',
  async (data, { rejectWithValue }) => {
    const addStoreData = {};
    addStoreData.data = data;
    addStoreData.csrf = authHeader();
    addStoreData.path = apiUrls.addStoreDetails(); // Your API endpoint

    try {
      const response = await APIs.post(addStoreData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// to Patch store details
export const patchStoreDetail = createAsyncThunk(
  'storeOverview/patchStoreDetails',
  async (data, { rejectWithValue }) => {
    console.log('patchStoreData', data);
    const patchStoreData = {};
    patchStoreData.data = data.data;
    patchStoreData.csrf = authHeader();
    // Your API endpoint
    patchStoreData.path = apiUrls.patchStoreDetails(data.id);
    console.log('patchStoreDataDetails', patchStoreData);

    try {
      const response = await APIs.patch(patchStoreData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// to get store details

export const getStoreDetail = createAsyncThunk(
  'storeOverview/getStoreDetails',
  async (data) => {
    console.log('storeData', data);
    const getStoreData = {};
    getStoreData.data = data;
    getStoreData.csrf = authHeader();
    getStoreData.path = apiUrls.getStoreDetails(data.id);
    console.log('gettingStoreDetails', getStoreData);

    try {
      const response = await APIs.fetch(getStoreData);
      console.log('response', response);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// /////////////////////// Store activity api////////////////////////////

// to get activity logs

export const getActivityLog = createAsyncThunk(
  'storeOverview/getActivityLog',
  async (data) => {
    console.log('activityLogs', data);
    const getActivityLogData = {};
    getActivityLogData.data = data;
    getActivityLogData.csrf = authHeader();
    getActivityLogData.path = apiUrls.getActivityLog(data);
    console.log('gettingActivityLogs', getActivityLogData);

    try {
      const response = await APIs.fetch(getActivityLogData);
      console.log('response', response);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);
