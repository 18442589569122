import { createSlice } from '@reduxjs/toolkit';
import * as authActionsCreator from '../actionsCreator/authActionsCreator';
import storage from '../../services/storageService';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    response: null,
    loading: false,
    authed: storage.getJWTToken() ? true : false,
    signUpError: null,
    loginResponse: null,
    loginError: null,
    loginOTPResponse: null,
    loginOTPError: null,
    resendEmailVerificationResponse: null,
    resendEmailVerificationError: null,
    emailVerifyResponse: null,
    emailVerifyError: null,
    resetEmailResponse: null,
    resetEmailError: null,
    resetPasswordResponse: null,
    resetPasswordError: null,
    error: null,
  },
  reducers: {},
  extraReducers: {
    // login

    [authActionsCreator.login.pending]: (state) => {
      state.loading = true;
      state.loginResponse = null;
      state.loginError = null;
    },
    [authActionsCreator.login.fulfilled]: (state, action) => {
      state.loading = false;
      state.loginResponse = action.payload;
      state.loginError = null;
    },
    [authActionsCreator.login.rejected]: (state, action) => {
      state.loading = false;
      state.loginResponse = null;
      state.loginError = action.payload;
    },

    // loginOTP

    [authActionsCreator.loginOTP.pending]: (state) => {
      state.loading = true;
      state.loginOTPResponse = null;
      state.loginOTPError = null;
    },
    [authActionsCreator.loginOTP.fulfilled]: (state, action) => {
      storage.setJWTToken(
        action.payload.token.access,
        action.payload.user.email,
        action.payload.user.id,
        action.payload.user.role
      );
      if (action.payload?.tenant?.domain) {
        storage.setTenantInfo(
          action.payload.tenant_status,
          action.payload.tenant.id,
          action.payload.tenant.domain,
          action.payload.tenant_subscription_status
        );
      }
      state.loading = false;
      state.loginOTPResponse = action.payload;
      state.authed = true;
      state.loginOTPError = null;
    },
    [authActionsCreator.loginOTP.rejected]: (state, action) => {
      state.loading = false;
      state.loginOTPResponse = null;
      state.authed = false;
      state.loginOTPError = action.payload;
    },

    // emailVerification

    [authActionsCreator.resendEmailVerification.pending]: (state) => {
      state.loading = true;
      state.resendEmailVerificationResponse = null;
      state.resendEmailVerificationError = null;
    },
    [authActionsCreator.resendEmailVerification.fulfilled]: (state, action) => {
      state.loading = false;
      state.resendEmailVerificationResponse = action.payload;
      state.resendEmailVerificationError = null;
    },
    [authActionsCreator.resendEmailVerification.rejected]: (state, action) => {
      state.loading = false;
      state.resendEmailVerificationResponse = null;
      state.resendEmailVerificationError = action.payload;
    },

    // emailVerify

    [authActionsCreator.emailVerify.pending]: (state) => {
      state.loading = true;
      state.emailVerifyResponse = null;
      state.emailVerifyError = null;
    },
    [authActionsCreator.emailVerify.fulfilled]: (state, action) => {
      state.loading = false;
      state.emailVerifyResponse = action.payload;
      state.emailVerifyError = null;
    },
    [authActionsCreator.emailVerify.rejected]: (state, action) => {
      state.loading = false;
      state.emailVerifyResponse = null;
      state.emailVerifyError = action.payload;
    },

    //  resetEmail

    [authActionsCreator.resetEmail.pending]: (state) => {
      state.loading = true;
      state.resetEmailResponse = null;
      state.resetEmailError = null;
    },
    [authActionsCreator.resetEmail.fulfilled]: (state, action) => {
      state.loading = false;
      state.resetEmailResponse = action.payload;
      state.resetEmailError = null;
    },
    [authActionsCreator.resetEmail.rejected]: (state, action) => {
      state.loading = false;
      state.resetEmailResponse = null;
      state.resetEmailError = action.payload;
    },

    //  resetPassword

    [authActionsCreator.resetPassword.pending]: (state) => {
      state.loading = true;
      state.resetPasswordResponse = null;
      state.resetPasswordError = null;
    },
    [authActionsCreator.resetPassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.resetPasswordResponse = action.payload;
      state.resetPasswordError = null;
    },
    [authActionsCreator.resetPassword.rejected]: (state, action) => {
      state.loading = false;
      state.resetPasswordResponse = null;
      state.resetPasswordError = action.payload;
    },

    // register

    [authActionsCreator.register.pending]: (state, action) => {
      state.loading = true;
      state.response = null;
      state.error = null;
    },
    [authActionsCreator.register.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
      state.error = null;
    },
    [authActionsCreator.register.rejected]: (state, action) => {
      state.loading = false;
      state.response = null;
      state.error = action.payload;
    },

    // logout

    [authActionsCreator.logOut]: (state) => {
      state.authed = false;
      state.response = null;
      state.loading = false;
      state.error = null;
    },

    // reset login

    [authActionsCreator.resetSignIn]: (state) => {
      state.error = null;
    },

    // reset signup

    [authActionsCreator.resetSignUp]: (state) => {
      state.error = null;
    },
  },
});

export default authSlice.reducer;
