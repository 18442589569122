import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import drawerRouter from '../../constants/drawerRoutes';
import { routesPath } from '../../constants/routesPath';
import * as productActionsCreator from '../../redux/actionsCreator/productActionsCreator';
import * as accountActionsCreator from '../../redux/actionsCreator/accountActionsCreator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const SideDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState(null);
  // Define the expanded state variable and handleAccordionChange function
  const [expanded, setExpanded] = useState(false);
  const drawerOpen = useSelector(
    (state) => state.productsReducer.drawerOpening
  );

  const classes = useStyles();

  useEffect(() => {
    setActiveLink(history.location.pathname);
  }, [location]);

  useEffect(() => {
    dispatch(accountActionsCreator.read());
  }, []);

  // Define the handleAccordionChange function
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="dashboard-drawer d-flex mt-5">
        <Drawer
          className={classes.drawer}
          variant={window.innerWidth >= 576 ? 'persistent' : 'temporary'}
          anchor="left"
          onClose={() => dispatch(productActionsCreator.drawerHandler())}
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Divider />
          <List>
            {drawerRouter.getDrawerRoutes().map((route) => {
              if (route.label === 'Settings') {
                return (
                  <div className="customStyling" key={route.label}>
                    <Accordion
                      expanded={expanded === route.label}
                      onChange={handleAccordionChange(route.label)}
                      style={{ paddingLeft: '8px' }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${route.label}-content`}
                        id={`${route.label}-header`}
                      >
                        {route.icon}
                        <span className="p-3 pb-1 pt-1">{route.label}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ListItem
                          button
                          onClick={() => {
                            history.push(routesPath.storeOverview);
                            setActiveLink(routesPath.storeOverview);
                          }}
                          selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Store Overview</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push(routesPath.salesAndMarketing);
                            setActiveLink(routesPath.salesAndMarketing);
                          }}
                          selected={activeLink === routesPath.salesAndMarketing}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Sales & Marketing</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push();
                            setActiveLink();
                          }}
                          // selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Product Management</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push();
                            setActiveLink();
                          }}
                          // selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Customer interaction</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push();
                            setActiveLink();
                          }}
                          // selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Financials</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push();
                            setActiveLink();
                          }}
                          // selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Operations</span>
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            history.push();
                            setActiveLink();
                          }}
                          // selected={activeLink === routesPath.storeOverview}
                          className="inactive-lists-item"
                          style={{ paddingLeft: '26px' }}
                        >
                          <span>Settings</span>
                        </ListItem>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              } else {
                return (
                  <ListItem
                    key={route.link}
                    onClick={() => {
                      history.push(route.link);
                      setActiveLink(route.link);
                    }}
                    selected={activeLink === route.link}
                    className="inactive-lists-item"
                    style={{ paddingLeft: '26px' }}
                    button
                  >
                    {route.icon}
                    <span className="p-3 pb-1 pt-1">{route.label}</span>
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
      </div>
    </>
  );
};
