import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../services/httpService';
import { apiUrls } from '../../constants/apiUrls';
import { authHeader } from '../../constants/authenticationHeader';
import errorhandler from '../../services/errorhandler';

// get payment method list
export const getPaymentMethods = createAsyncThunk(
  'payment/getPaymentMethods',
  async (data = {}, { rejectWithValue }) => {
    const paymentListData = {};
    paymentListData.path = apiUrls.getPaymentMethods();
    paymentListData.csrf = authHeader();
    data.path = apiUrls.getDefaultPaymentMethod();
    data.csrf = authHeader();
    try {
      const response = await APIs.fetch(paymentListData);
      const defaultCard = await APIs.post(data);
      const cards = response.data.map((card) => {
        if (card.id === defaultCard.data.id) {
          card.default = true;
        }
        return card;
      });
      return cards;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// create setup Intent
export const createSetupIntent = createAsyncThunk(
  'payment/createSetupIntent',
  async (data = {}, { rejectWithValue }) => {
    const createSetupIntentData = {};
    createSetupIntentData.path = apiUrls.createSetupIntent();
    createSetupIntentData.csrf = authHeader();
    try {
      const response = await APIs.post(createSetupIntentData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// delete payment methods
export const deletePaymentMethod = createAsyncThunk(
  'payment/deletePaymentMethod',
  async (data = {}, { rejectWithValue }) => {
    const deletePaymethodData = {};
    const { card, ...deleteData } = data;
    deletePaymethodData.data = deleteData;
    deletePaymethodData.path = apiUrls.deletePaymentMethod();
    deletePaymethodData.csrf = authHeader();
    try {
      const response = await APIs.post(deletePaymethodData);
      return { response: response.data, card: card };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// change default card
export const updatePaymentCard = createAsyncThunk(
  'payment/updatePaymentCard',
  async (data = {}, { rejectWithValue }) => {
    const updateCardData = {};
    updateCardData.data = data;
    updateCardData.path = apiUrls.updatePaymentCard();
    updateCardData.csrf = authHeader();
    try {
      const response = await APIs.post(updateCardData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

export const resetPayments = createAction('payment/resetPayments');
