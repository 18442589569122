const parseError = (errorBody) => {
  try {
    let parsedError = '';
    if (typeof errorBody === 'object' && errorBody !== null) {
      for (const param in errorBody) {
        if (errorBody[param].constructor === Array) {
          errorBody[param].map((err) => {
            if (!err.includes(param)) {
              parsedError = parsedError + err.replace('This', param);
            } else {
              parsedError = parsedError + err;
            }
          });
        } else if (typeof errorBody[param] === 'object') {
          return parseError(errorBody[param]);
        } else {
          if (param !== 'message') {
            parsedError = errorBody[param];
          }
        }
      }
    } else if (errorBody.constructor === Array) {
      parsedError = errorBody.map((error) => {
        return parsedError + error;
      });
    } else if (errorBody.includes(':')) {
      parsedError = errorBody.split(':')[1];
    } else {
      parsedError = errorBody;
    }

    return parsedError;
  } catch {
    return 'An Unexpected Error Occured!';
  }
};

export default {
  parseError,
};
