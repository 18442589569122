import { createSlice } from '@reduxjs/toolkit';
import * as receiptActionsCreator from '../actionsCreator/recieptActionsCreator';

const recieptSlice = createSlice({
  name: 'reciept',
  initialState: {
    quoteMetadata: {
      loading: false,
      error: false,
      response: null,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(receiptActionsCreator.getQuoteMetadata.pending, (state) => {
        state.quoteMetadata.loading = true;
        state.quoteMetadata.error = false;
        state.quoteMetadata.response = null;
      })
      .addCase(
        receiptActionsCreator.getQuoteMetadata.fulfilled,
        (state, action) => {
          state.quoteMetadata.loading = false;
          state.quoteMetadata.response = action.payload;
          state.quoteMetadata.error = false;
        }
      )
      .addCase(
        receiptActionsCreator.getQuoteMetadata.rejected,
        (state, action) => {
          state.quoteMetadata.loading = false;
          state.quoteMetadata.error = action.payload;
          state.quoteMetadata.response = null;
        }
      );
  },
});

export default recieptSlice.reducer;
