import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../services/httpService';
import { apiUrls } from '../../constants/apiUrls';
import { authHeader } from '../../constants/authenticationHeader';
import errorhandler from '../../services/errorhandler';

// get quote items
export const getQuotes = createAsyncThunk(
  'quotes/getSubQuotes',
  async (data = {}) => {
    const subQuotesData = {};
    subQuotesData.path = apiUrls.getSubQuotes(data);
    subQuotesData.csrf = authHeader();
    const response = await APIs.fetch(subQuotesData);
    return response.data;
  }
);

// get all Users

export const allUser = createAsyncThunk(
  '/allUsers',
  async (data = {}, { rejectWithValue }) => {
    const allUsersData = {};
    allUsersData.data = data;
    allUsersData.path = apiUrls.allUser();
    allUsersData.csrf = authHeader();

    try {
      const response = await APIs.fetch(allUsersData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// get quote items
export const getAllQuotes = createAsyncThunk(
  'quotes/getAllQuotes',
  async (data = {}) => {
    const subQuotesData = {};
    subQuotesData.path = apiUrls.getQuotes(data);
    subQuotesData.csrf = authHeader();
    const response = await APIs.fetch(subQuotesData);
    return response.data;
  }
);

// patch quote Items
export const patchQuoteItems = createAsyncThunk(
  'quotes/patchQuoteItems',
  async (data = {}, { rejectWithValue }) => {
    const quoteItemsData = {};
    quoteItemsData.data = data;
    quoteItemsData.path = apiUrls.patchQuoteItem(data.itemId);
    quoteItemsData.csrf = authHeader();

    try {
      const response = await APIs.patch(quoteItemsData);
      return {
        response: response.data,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// getQuoteById
export const getQuoteById = createAsyncThunk(
  'quotes/getQuoteById',
  async (data = {}) => {
    const quoteByIdData = {};
    quoteByIdData.data = data;
    quoteByIdData.path = apiUrls.getQuoteById(data);
    quoteByIdData.csrf = authHeader();
    const response = await APIs.fetch(quoteByIdData);
    return response.data;
  }
);

// getUserById
export const getUserById = createAsyncThunk(
  'user/getUserById',
  async (data = {}) => {
    const userByIdData = {};
    userByIdData.data = data;
    userByIdData.path = apiUrls.getUserById(data);
    userByIdData.csrf = authHeader();
    const response = await APIs.fetch(userByIdData);
    return response.data;
  }
);

// get possible transactions
export const getpossibleTransitions = createAsyncThunk(
  'quotes/getpossibleTransitions',
  async (data = {}) => {
    const possibleTransitionsData = {};
    data.type === 'VENDOR'
      ? (possibleTransitionsData.path = apiUrls.getpossibleTransitions(data.id))
      : (possibleTransitionsData.path = apiUrls.getQuotesPossibleTransitions(
          data.id
        ));

    possibleTransitionsData.csrf = authHeader();
    const response = await APIs.fetch(possibleTransitionsData);
    return response.data;
  }
);

// get possible transactions
export const postpossibleTransitions = createAsyncThunk(
  'quotes/postpossibleTransitions',
  async (data = {}) => {
    const postPossibleTransitionsData = {};
    postPossibleTransitionsData.data = data;
    if (data.type === 'VENDOR') {
      postPossibleTransitionsData.path = `${apiUrls.postQuotepossibleTransitions(
        data.subQuoteId
      )}${data.transition}/
      `;
    } else {
      postPossibleTransitionsData.path = `${apiUrls.postpossibleTransitions(
        data.subQuoteId
      )}${data.transition}/
      `;
    }
    postPossibleTransitionsData.csrf = authHeader();
    const response = await APIs.post(postPossibleTransitionsData);
    return { data: response.data, productType: data.productType };
  }
);

export const priceHandler = createAction(
  'quotes/clear',
  function prepare(data) {
    return {
      payload: data,
    };
  }
);

// //patch downpayment for quote
// export const patchDownPaymentForQuote = createAsyncThunk(
//   "quotes/patchDownPayment",
//   async (data = {}) => {
//     let downPaymentData = {};
//     downPaymentData.data = data;
//     downPaymentData.path = apiUrls.patchDownPayment(data.id);
//     downPaymentData.csrf = authHeader();
//     const response = await APIs.patch(downPaymentData);
//     return response;
//   }
// );

// patch downpayment for quote
export const patchDownPaymentForQuote = createAsyncThunk(
  'quotes/patchDownPayment',
  async (data = {}) => {
    const downPaymentData = {};
    const { quotes, ...downPaymentPayloadData } = data;
    downPaymentData.data = downPaymentPayloadData;
    downPaymentData.path = apiUrls.patchDownPayment(data.id);

    downPaymentData.csrf = authHeader();
    const response = await APIs.patch(downPaymentData);
    return {
      response: response,
      quotes: quotes,
      downPayment: data.down_payment,
    };
  }
);

// delete quote

export const deleteQuote = createAsyncThunk(
  'quotes/deleteQuote',
  async (data, { rejectWithValue }) => {
    const deleteQuoteData = {};
    deleteQuoteData.data = data.data;
    deleteQuoteData.csrf = authHeader();
    deleteQuoteData.path = apiUrls.deleteQuote(data.id);

    try {
      const response = await APIs.dell(deleteQuoteData);

      return {
        response: response.data,
        products: data.products,
        productIndex: data.productIndex,
      };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// addQuote

export const addQuote = createAsyncThunk(
  'quotes/addQuote',
  async (data = {}, { rejectWithValue }) => {
    const addQuoteData = {};
    addQuoteData.data = data.data;
    addQuoteData.path = apiUrls.addQuote();
    addQuoteData.csrf = authHeader();

    try {
      const response = await APIs.post(addQuoteData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.detail);
    }
  }
);

// quoteUpdate

export const updateQuote = createAsyncThunk(
  'quotes/updateQuote',
  async (data = {}, { rejectWithValue }) => {
    const updateQuoteData = {};
    updateQuoteData.data = data;
    updateQuoteData.path = apiUrls.quoteUpdate();
    updateQuoteData.csrf = authHeader();
    try {
      const response = await APIs.post(updateQuoteData);
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.detail);
    }
  }
);

export const clearTransitions = createAction('quotes/clearTransitions');

export const clearQuoteUpdateData = createAction('quotes/clearUpdateData');

export const clearDownPayment = createAction('quotes/clearDownPayment');
