import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../services/httpService';
import { apiUrls } from '../../constants/apiUrls';
import { authHeader } from '../../constants/authenticationHeader';

// get orders
export const getOrders = createAsyncThunk(
  'orders/getOrders',
  async (data = {}) => {
    const ordersData = {};
    ordersData.path = apiUrls.getOrders(data);
    ordersData.csrf = authHeader();
    const response = await APIs.fetch(ordersData);
    return response.data;
  }
);

// get subOrders
export const getSubOrders = createAsyncThunk(
  'orders/getSubOrders',
  async (data = {}) => {
    const subOrdersData = {};
    subOrdersData.path = apiUrls.getSubOrders(data);
    subOrdersData.csrf = authHeader();
    const response = await APIs.fetch(subOrdersData);
    return response.data;
  }
);

// set transfer handler

export const amountTransferHandler = createAsyncThunk(
  'orders/subOrdersAmountTransfer',
  async (data = {}) => {
    const subOrdersData = {};
    subOrdersData.data = data;
    subOrdersData.path = apiUrls.transferSubOrdersAmount();
    subOrdersData.csrf = authHeader();
    const response = await APIs.post(subOrdersData);
    return { data: response.data, subOrderId: data.suborder_id };
  }
);

export const resetTransferData = createAction('orders/resetTranferData');
