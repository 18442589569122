/* eslint-disable import/no-unused-modules */
/* eslint-disable react/react-in-jsx-scope */
import { AddProductIcon } from '../components/ui/addProductIcon';
import { Home } from '../components/ui/HomeIcon';
import { SettingsIcon } from '../components/ui/SettingsIcon';
import { InventoryIcon } from '../components/ui/inventoryIcon';
import { OrdersIcon } from '../components/ui/OrdersIcon';
import { QuotesIcon } from '../components/ui/quotesIcon';
import { SubscriptionIcon } from '../components/ui/subscriptionIcon';
import { PaymentIcon } from '../components/ui/paymentIcon';
import { routesPath } from './routesPath';
import storageService from '../services/storageService';

const getDrawerRoutes = () => {
  let drawerRouter = [];

  const adminRoutes = [
    { label: 'Quotes', link: routesPath.quotes, icon: <QuotesIcon /> },
    { label: 'Orders', link: routesPath.orders, icon: <OrdersIcon /> },
  ];
  const vendorRoutes = [
    { label: 'Sub Quotes', link: routesPath.subQuotes, icon: <QuotesIcon /> },
    { label: 'Sub Orders', link: routesPath.subOrders, icon: <OrdersIcon /> },

    {
      label: 'Subscription',
      link: routesPath.subscription,
      icon: <SubscriptionIcon />,
    },
    {
      label: 'Payment Methods',
      link: routesPath.payments,
      icon: <PaymentIcon />,
    },
  ];

  const tenantRoutes = [
    {
      label: 'Tenant Products',
      link: routesPath.tenantProduct,
      icon: <AddProductIcon />,
    },
    {
      label: 'Tenant Quotes',
      link: routesPath.tenantQuotes,
      icon: <QuotesIcon />,
    },
    {
      label: 'Tenant Orders',
      link: routesPath.tenantOrders,
      icon: <OrdersIcon />,
    },
    {
      label: 'Settings',
      icon: <SettingsIcon />,
    },
  ];

  drawerRouter = [
    { label: 'Home', link: routesPath.profile, icon: <Home /> },
    { label: 'Inventory', link: routesPath.inventory, icon: <InventoryIcon /> },
    { label: 'Products', link: routesPath.products, icon: <AddProductIcon /> },
  ];

  if (storageService.getUserRole() === 'ADMIN') {
    adminRoutes.map((route) => drawerRouter.push(route));
  }
  if (storageService.getUserRole() === 'VENDOR') {
    vendorRoutes.map((route) => drawerRouter.push(route));
  }
  if (storageService.getTenantId()) {
    tenantRoutes.map((route) => drawerRouter.push(route));
  }

  return drawerRouter;
};

export default {
  getDrawerRoutes,
};
