import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import APIs from '../../services/httpService';
import { apiUrls } from '../../constants/apiUrls';
import { authHeader } from '../../constants/authenticationHeader';
import errorhandler from '../../services/errorhandler';

// get all subscriptions
export const getSubscription = createAsyncThunk(
  'subscription/getSubscriptions',
  async (data = {}, { rejectWithValue }) => {
    const getSubscriptionsData = {};

    getSubscriptionsData.path = apiUrls.getSubscriptions(data);
    getSubscriptionsData.csrf = authHeader();

    try {
      const response = await APIs.fetch(getSubscriptionsData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// get subscribed plans
export const getSubscribedPlans = createAsyncThunk(
  'subscription/getSubscribedPlans',
  async (data = {}, { rejectWithValue }) => {
    const getSubscribedPlansData = {};
    getSubscribedPlansData.path = apiUrls.getSubscribedPlans(data);
    getSubscribedPlansData.csrf = authHeader();

    try {
      const response = await APIs.post(getSubscribedPlansData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// choose subscribed plan
export const chooseSubscriptionPlan = createAsyncThunk(
  'subscription/chooseSubscriptionPlan',
  async (data = {}, { rejectWithValue }) => {
    const chooseSubscriptionsData = {};
    chooseSubscriptionsData.data = data;
    chooseSubscriptionsData.path = apiUrls.chooseSubscriptionPlan(data);
    chooseSubscriptionsData.csrf = authHeader();

    try {
      const response = await APIs.post(chooseSubscriptionsData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// delete subscription
export const cancelSubscriptionPlan = createAsyncThunk(
  'subscription/cancelSubscriptionPlan',
  async (data = {}, { rejectWithValue }) => {
    const cancelSubscriptionPlanData = {};
    const { plan, ...cancelData } = data;
    cancelSubscriptionPlanData.data = cancelData;
    cancelSubscriptionPlanData.path = apiUrls.cancelSubscription();
    cancelSubscriptionPlanData.csrf = authHeader();

    try {
      const response = await APIs.post(cancelSubscriptionPlanData);
      return { response: response.data, plan: plan };
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// delete subscription
export const updateSubscriptionPlan = createAsyncThunk(
  'subscription/updateSubscriptionPlan',
  async (data = {}, { rejectWithValue }) => {
    const updateSubscriptionPlanData = {};
    updateSubscriptionPlanData.data = data;
    updateSubscriptionPlanData.path = apiUrls.updateSubscriptionPlan();
    updateSubscriptionPlanData.csrf = authHeader();

    try {
      const response = await APIs.post(updateSubscriptionPlanData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

export const resetSubscriptions = createAction(
  'subscription/resetSubscriptions'
);
