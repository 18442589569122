import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls } from '../../constants/apiUrls';
import APIs from '../../services/httpService';
import { authHeader } from '../../constants/authenticationHeader';
import errorhandler from '../../services/errorhandler';

// login

export const login = createAsyncThunk(
  'auth/login',
  async (data = {}, { rejectWithValue }) => {
    const loginData = {};
    loginData.data = data;
    loginData.path = apiUrls.loginUser();

    try {
      const response = await APIs.post(loginData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.detail);
    }
  }
);

// loginOTP

export const loginOTP = createAsyncThunk(
  'auth/loginOTP',
  async (data = {}, { rejectWithValue }) => {
    const loginOTPData = {};
    loginOTPData.data = data;
    loginOTPData.path = apiUrls.loginOTP();

    try {
      const response = await APIs.post(loginOTPData);
      return response.data;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.non_field_errors[0] || 'Something Went Wrong!'
      );
    }
  }
);

// resendEmailVerification

export const resendEmailVerification = createAsyncThunk(
  'auth/resendEmailVerification',
  async (data = {}, { rejectWithValue }) => {
    const resendEmailVerificationData = {};
    resendEmailVerificationData.data = data;
    resendEmailVerificationData.path = apiUrls.resendEmailVerification();

    try {
      const response = await APIs.post(resendEmailVerificationData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// emailVerify

export const emailVerify = createAsyncThunk(
  'auth/emailVerify',
  async (data = {}, { rejectWithValue }) => {
    const emailVerifyData = {};
    emailVerifyData.data = data;
    emailVerifyData.path = apiUrls.emailVerify(emailVerifyData?.data);

    try {
      const response = await APIs.post(emailVerifyData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Email - to resetPassword

export const resetEmail = createAsyncThunk(
  'auth/resetEmail',
  async (data = {}, { rejectWithValue }) => {
    const resetEmailData = {};
    resetEmailData.data = data;
    resetEmailData.path = apiUrls.resetPassword();

    try {
      const response = await APIs.post(resetEmailData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// resetPassword

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data = {}, { rejectWithValue }) => {
    const resetPasswordData = {};
    resetPasswordData.data = data;
    resetPasswordData.path = apiUrls.resetPasswordConfirm();

    try {
      const response = await APIs.post(resetPasswordData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Register

export const register = createAsyncThunk(
  'auth/register',
  async (data = {}, { rejectWithValue }) => {
    const signUpData = {};
    signUpData.data = data;
    signUpData.path = apiUrls.signUpVendor();

    try {
      const response = await APIs.post(signUpData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// check for login

export const checkLogin = createAsyncThunk(
  'auth/me',
  async (data = {}, { rejectWithValue }) => {
    const LoginData = {};
    LoginData.csrf = authHeader();
    LoginData.path = apiUrls.checkLogin();

    try {
      const response = await APIs.fetch(LoginData);
      return response.data;
    } catch (err) {
      return rejectWithValue(errorhandler.parseError(err.response?.data));
    }
  }
);

// logout

export const logOut = createAction('auth/logout');
export const resetSignIn = createAction('auth/resetSignIn');
export const resetSignUp = createAction('auth/resetSignUp');
