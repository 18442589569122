import { createSlice } from '@reduxjs/toolkit';
import * as productActionsCreator from '../actionsCreator/productActionsCreator';

const productSlice = createSlice({
  name: 'products',
  initialState: {
    application: null,
    subCategories: null,
    equipments: null,
    models: null,
    loading: false,
    addedImages: null,
    url: null,
    addProductResponse: null,
    searchProduct: null,
    addProductSuccess: false,
    addProductError: null,
    drawerOpening: window.innerWidth >= 768 ? true : false,
    toEditProduct: null,
    toEditProductLoading: false,
    toEditProductError: false,
    deleteProductError: null,
    combinedProducts: null,
  },
  reducers: {},
  extraReducers: {
    // get Applications

    [productActionsCreator.getApplication.pending]: (state) => {
      state.loading = true;
      state.appilcation = null;
    },
    [productActionsCreator.getApplication.fulfilled]: (state, action) => {
      state.loading = false;
      state.application = action.payload;
    },
    [productActionsCreator.getApplication.rejected]: (state) => {
      state.loading = false;
      state.application = null;
    },

    // get Models

    [productActionsCreator.getModels.pending]: (state) => {
      state.loading = true;
      state.models = null;
    },
    [productActionsCreator.getModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.models = action.payload;
    },
    [productActionsCreator.getModels.rejected]: (state) => {
      state.loading = false;
      state.models = null;
    },

    // get subcategories

    [productActionsCreator.getSubCategories.pending]: (state) => {
      state.loading = true;
      state.subCategories = null;
    },
    [productActionsCreator.getSubCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.subCategories = action.payload;
    },
    [productActionsCreator.getSubCategories.rejected]: (state) => {
      state.loading = false;
      state.subCategories = null;
    },

    // add components

    [productActionsCreator.addComponents.pending]: (state) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
    },
    [productActionsCreator.addComponents.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload;
      state.addProductSuccess = true;
      state.addProductError = null;
    },
    [productActionsCreator.addComponents.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = action.payload;
    },

    // add Model

    [productActionsCreator.addModels.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
    },
    [productActionsCreator.addModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload;
      state.addProductSuccess = true;
      state.addProductError = null;
    },
    [productActionsCreator.addModels.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = action.payload;
    },

    // add combined-products

    [productActionsCreator.addCombinedProduct.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
    },
    [productActionsCreator.addCombinedProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload;
      state.addProductSuccess = true;
      state.addProductError = null;
    },
    [productActionsCreator.addCombinedProduct.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = action.payload;
    },

    // patch combined-products

    [productActionsCreator.patchCombinedProduct.pending]: (state, action) => {
      state.loading = true;
      state.addProductResponse = null;
      state.addProductError = null;
    },

    [productActionsCreator.patchCombinedProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.addProductResponse = action.payload.response;
      state.addProductError = null;
    },

    [productActionsCreator.patchCombinedProduct.rejected]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductError = action.payload;
    },

    // get Equipments

    [productActionsCreator.getEquipment.pending]: (state) => {
      state.loading = true;
      state.equipments = null;
    },
    [productActionsCreator.getEquipment.fulfilled]: (state, action) => {
      state.loading = false;
      state.equipments = action.payload;
    },
    [productActionsCreator.getEquipment.rejected]: (state) => {
      state.loading = false;
      state.equipments = null;
    },

    // //get Models for vendors ( getting products )

    // [productActionsCreator.getProducts.pending]: (state) => {
    //   state.loading = true;
    //   state.searchProduct = null;
    // },
    // [productActionsCreator.getProducts.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.searchProduct = action.payload;
    // },
    // [productActionsCreator.getProducts.rejected]: (state) => {
    //   state.loading = false;
    //   state.searchProduct = null;
    // },

    // ( getting searched combined product )

    [productActionsCreator.getCombinedProductsSearch.pending]: (state) => {
      state.loading = true;
      state.searchProduct = null;
    },
    [productActionsCreator.getCombinedProductsSearch.fulfilled]: (
      state,
      action
    ) => {
      state.loading = false;
      state.searchProduct = action.payload;
    },
    [productActionsCreator.getCombinedProductsSearch.rejected]: (state) => {
      state.loading = false;
      state.searchProduct = null;
    },

    // delete Combined-Products

    [productActionsCreator.deleteCombinedProducts.pending]: (state) => {
      state.loading = true;
      state.searchProduct = null;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteCombinedProducts.fulfilled]: (
      state,
      action
    ) => {
      state.loading = false;
      action.payload.products.splice(action.payload.productIndex, 1);
      state.combinedProducts = action.payload.products;
      state.searchProduct = action.payload.products;
      state.deleteProductError = null;
    },
    [productActionsCreator.deleteCombinedProducts.rejected]: (
      state,
      action
    ) => {
      state.loading = false;
      state.searchProduct = null;
      state.deleteProductError = action.payload;
    },

    [productActionsCreator.removeProduct]: (state, action) => {
      state.toEditProduct = null;
      state.toEditProductError = null;
      state.toEditProductLoading = false;
    },

    // get combined-products

    [productActionsCreator.getCombinedProducts.pending]: (state) => {
      state.loading = true;
      state.combinedProducts = null;
    },
    [productActionsCreator.getCombinedProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.combinedProducts = action.payload;
    },
    [productActionsCreator.getCombinedProducts.rejected]: (state, action) => {
      state.loading = false;
      state.combinedProducts = null;
    },

    // drawer handler

    [productActionsCreator.drawerHandler]: (state, action) => {
      state.drawerOpening = !state.drawerOpening;
    },
    [productActionsCreator.resetInventoryProductPatch]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
      state.searchProduct = null;
      state.toEditProductError = null;
      state.toEditProduct = null;
    },
    [productActionsCreator.resetProductData]: (state, action) => {
      state.loading = false;
      state.addProductResponse = null;
      state.addProductSuccess = false;
      state.addProductError = null;
      state.searchProduct = null;
      state.toEditProductError = null;
      state.toEditProduct = null;
      state.deleteProductError = null;
    },
  },
});

export default productSlice.reducer;
