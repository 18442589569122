import { createSlice } from '@reduxjs/toolkit';
import * as storeOverviewActionCreator from '../actionsCreator/storeOverviewActionCreator';

const storeSlice = createSlice({
  name: 'storeOverview',
  initialState: {
    loading: null,
    addStoreDataRespose: null,
    addStoreDataSuccess: false,
    addStoreDataError: null,
    storeDetails: null,
    patchStoreDetails: null,
    activityLogData: null,
  },
  reducers: {},
  extraReducers: {
    // add store details data

    [storeOverviewActionCreator.addStoreDetail.pending]: (state, action) => {
      state.loading = true;
      state.addStoreDataResponse = null;
      state.addStoreDataSuccess = false;
      state.addStoreDataError = null;
    },
    [storeOverviewActionCreator.addStoreDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.addStoreDataResponse = action.payload;
      state.addStoreDataSuccess = true;
      state.adStoreDatatError = null;
    },
    [storeOverviewActionCreator.addStoreDetail.rejected]: (state, action) => {
      state.loading = false;
      state.addStoreDataResponse = null;
      state.addStoreDataSuccess = false;
      state.addStoreDataError = action.payload;
    },

    // get store details data

    [storeOverviewActionCreator.getStoreDetail.pending]: (state) => {
      state.loading = true;
      state.storeDetails = null;
    },
    [storeOverviewActionCreator.getStoreDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeDetails = action.payload;
    },
    [storeOverviewActionCreator.getStoreDetail.rejected]: (state, action) => {
      state.loading = false;
      state.storeDetails = null;
    },

    // patch store details data

    [storeOverviewActionCreator.patchStoreDetail.pending]: (state) => {
      state.loading = true;
      state.patchStoreDetails = null;
    },
    [storeOverviewActionCreator.patchStoreDetail.fulfilled]: (
      state,
      action
    ) => {
      state.loading = false;
      state.patchStoreDetails = action.payload;
    },
    [storeOverviewActionCreator.patchStoreDetail.rejected]: (state, action) => {
      state.loading = false;
      state.patchStoreDetails = null;
    },

    // getting store activity log data

    [storeOverviewActionCreator.getActivityLog.pending]: (state) => {
      state.loading = true;
      state.activityLogData = null;
    },
    [storeOverviewActionCreator.getActivityLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.activityLogData = action.payload;
    },
    [storeOverviewActionCreator.getActivityLog.rejected]: (state, action) => {
      state.loading = false;
      state.activityLogData = null;
    },
  },
});

export default storeSlice.reducer;
