import React from 'react';

export const Home = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15 21V16C15 15.2044 14.6839 14.4413 14.1213 13.8787C13.5587 13.3161 12.7956 13 12 13C11.2044 13 10.4413 13.3161 9.87868 13.8787C9.31607 14.4413 9 15.2044 9 16V21M7.606 5.65L5.006 8.106C4.266 8.804 3.896 9.153 3.632 9.566C3.39736 9.93246 3.22408 10.3347 3.119 10.757C3 11.233 3 11.742 3 12.76V14.6C3 16.84 3 17.96 3.436 18.816C3.81949 19.5686 4.43139 20.1805 5.184 20.564C6.04 21 7.16 21 9.4 21H14.6C16.84 21 17.96 21 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C21 17.96 21 16.84 21 14.6V12.759C21 11.742 21 11.233 20.881 10.757C20.7758 10.3351 20.6025 9.93315 20.368 9.567C20.103 9.153 19.734 8.804 18.994 8.106L16.394 5.65C14.848 4.19 14.074 3.46 13.193 3.184C12.4162 2.94127 11.5838 2.94127 10.807 3.184C9.925 3.459 9.152 4.19 7.606 5.65Z"
        stroke="#3D4752"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
