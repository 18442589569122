import React from 'react';
export const PaymentIcon = () => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5215 3.32227V2.47852C16.5215 1.51202 15.738 0.728516 14.7715 0.728516H2.27148C1.30498 0.728516 0.521484 1.51202 0.521484 2.47852V3.32227C0.521484 3.40855 0.591453 3.47852 0.677734 3.47852H16.3652C16.4515 3.47852 16.5215 3.40855 16.5215 3.32227Z"
      fill="#13214A"
    />
    <path
      d="M0.521484 4.63477V9.97852C0.521484 10.945 1.30498 11.7285 2.27148 11.7285H14.7715C15.738 11.7285 16.5215 10.945 16.5215 9.97852V4.63477C16.5215 4.54848 16.4515 4.47852 16.3652 4.47852H0.677734C0.591453 4.47852 0.521484 4.54848 0.521484 4.63477ZM4.52148 8.72852C4.52148 9.00464 4.29761 9.22852 4.02148 9.22852H3.52148C3.24536 9.22852 3.02148 9.00464 3.02148 8.72852V8.22852C3.02148 7.95239 3.24536 7.72852 3.52148 7.72852H4.02148C4.29761 7.72852 4.52148 7.95239 4.52148 8.22852V8.72852Z"
      fill="#13214A"
    />
  </svg>
);
