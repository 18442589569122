import React from 'react';
export const AddProductIcon = () => (
  // <svg
  //   id="Capa_1"
  //   enableBackground="new 0 0 512.017 512.017"
  //   height="20"
  //   viewBox="0 0 512.017 512.017"
  //   width="20"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <g>
  //     <path d="m244.072 126.978v-126.978h-66.498v126.978l33.249-14.716z" />
  //     <path d="m.008 0h147.565v49.332h-147.565z" />
  //     <path d="m287.008 399.517c0 62.033 50.468 112.5 112.5 112.5s112.5-50.467 112.5-112.5-50.468-112.5-112.5-112.5-112.5 50.467-112.5 112.5zm182.601-15v30h-55.101v55.101h-30v-55.101h-55.101v-30h55.101v-55.101h30v55.101z" />
  //     <path d="m257.008 399.517c0-78.575 63.925-142.5 142.5-142.5 6.924 0 13.731.507 20.394 1.465v-179.15h-145.83v93.73l-63.249-27.994-63.249 27.994v-93.73h-147.566v338.826h258.22c-.801-6.102-1.22-12.324-1.22-18.641zm-142.75-30.691h-64.199v-30h64.199zm32.166-64.332h-96.365v-30h96.365z" />
  //     <path d="m274.072 0h145.83v49.332h-145.83z" />
  //   </g>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 6.2C3 5.08 3 4.52 3.218 4.092C3.40974 3.71569 3.71569 3.40974 4.092 3.218C4.52 3 5.08 3 6.2 3H6.8C7.92 3 8.48 3 8.908 3.218C9.28431 3.40974 9.59026 3.71569 9.782 4.092C10 4.52 10 5.08 10 6.2V6.8C10 7.92 10 8.48 9.782 8.908C9.59026 9.28431 9.28431 9.59026 8.908 9.782C8.48 10 7.92 10 6.8 10H6.2C5.08 10 4.52 10 4.092 9.782C3.71569 9.59026 3.40974 9.28431 3.218 8.908C3 8.48 3 7.92 3 6.8V6.2Z"
      stroke="#3D4752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 17.2C3 16.08 3 15.52 3.218 15.092C3.40974 14.7157 3.71569 14.4097 4.092 14.218C4.52 14 5.08 14 6.2 14H6.8C7.92 14 8.48 14 8.908 14.218C9.28431 14.4097 9.59026 14.7157 9.782 15.092C10 15.52 10 16.08 10 17.2V17.8C10 18.92 10 19.48 9.782 19.908C9.59026 20.2843 9.28431 20.5903 8.908 20.782C8.48 21 7.92 21 6.8 21H6.2C5.08 21 4.52 21 4.092 20.782C3.71569 20.5903 3.40974 20.2843 3.218 19.908C3 19.48 3 18.92 3 17.8V17.2Z"
      stroke="#3D4752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 6.2C14 5.08 14 4.52 14.218 4.092C14.4097 3.71569 14.7157 3.40974 15.092 3.218C15.52 3 16.08 3 17.2 3H17.8C18.92 3 19.48 3 19.908 3.218C20.2843 3.40974 20.5903 3.71569 20.782 4.092C21 4.52 21 5.08 21 6.2V6.8C21 7.92 21 8.48 20.782 8.908C20.5903 9.28431 20.2843 9.59026 19.908 9.782C19.48 10 18.92 10 17.8 10H17.2C16.08 10 15.52 10 15.092 9.782C14.7157 9.59026 14.4097 9.28431 14.218 8.908C14 8.48 14 7.92 14 6.8V6.2Z"
      stroke="#3D4752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 17.2C14 16.08 14 15.52 14.218 15.092C14.4097 14.7157 14.7157 14.4097 15.092 14.218C15.52 14 16.08 14 17.2 14H17.8C18.92 14 19.48 14 19.908 14.218C20.2843 14.4097 20.5903 14.7157 20.782 15.092C21 15.52 21 16.08 21 17.2V17.8C21 18.92 21 19.48 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.48 21 18.92 21 17.8 21H17.2C16.08 21 15.52 21 15.092 20.782C14.7157 20.5903 14.4097 20.2843 14.218 19.908C14 19.48 14 18.92 14 17.8V17.2Z"
      stroke="#3D4752"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
