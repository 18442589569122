import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as ordersActionsCreator from '../actionsCreator/ordersActionsCreator';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: null,
    subOrders: null,
    response: null,
    loading: false,
    error: null,
    subOrdersTrasnferResponse: null,
    subOrdersTrasnferError: null,
    subOrdersTrasnferLoading: null,
  },
  extraReducers: {
    // get Orders

    [ordersActionsCreator.getOrders.pending]: (state) => {
      state.loading = true;
      state.orders = null;
      state.error = null;
    },
    [ordersActionsCreator.getOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.orders = action.payload;
      state.error = null;
    },
    [ordersActionsCreator.getOrders.rejected]: (state, action) => {
      state.loading = false;
      state.orders = null;
      state.error = action.payload;
    },

    // get subOrders

    [ordersActionsCreator.getSubOrders.pending]: (state) => {
      state.loading = true;
      state.subOrders = null;
      state.error = null;
    },
    [ordersActionsCreator.getSubOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.subOrders = action.payload;
      state.error = null;
    },
    [ordersActionsCreator.getSubOrders.rejected]: (state, action) => {
      state.loading = false;
      state.subOrders = null;
      state.error = action.payload;
    },

    // transfer subOrders account payments

    [ordersActionsCreator.amountTransferHandler.pending]: (state) => {
      state.subOrdersTrasnferLoading = true;
      state.subOrdersTrasnferResponse = null;
      state.subOrdersTrasnferError = null;
    },
    [ordersActionsCreator.amountTransferHandler.fulfilled]: (state, action) => {
      const tempSubOrders = _.cloneDeep(state.subOrders);

      tempSubOrders.map((subOrder, key) => {
        if (subOrder.id === action.payload.subOrderId) {
          tempSubOrders[key] = action.payload.data;
        }
      });
      state.subOrdersTrasnferLoading = true;
      state.subOrdersTrasnferResponse = action.payload;
      state.subOrdersTrasnferError = null;
      state.subOrders = tempSubOrders;
    },
    [ordersActionsCreator.amountTransferHandler.rejected]: (state, action) => {
      state.subOrdersTrasnferLoading = false;
      state.subOrdersTrasnferResponse = null;
      state.subOrdersTrasnferError = action.payload;
    },

    // clear transfer details

    [ordersActionsCreator.resetTransferData]: (state, action) => {
      state.subOrdersTrasnferLoading = null;
      state.subOrdersTrasnferResponse = null;
      state.subOrdersTrasnferError = null;
    },
  },
});

export default ordersSlice.reducer;
