import { configureStore } from '@reduxjs/toolkit';
import productsReducer from '../reducers/productReducer';
import authReducer from '../reducers/authReducer';
import quotesReducer from '../reducers/quotesReducer';
import ordersReducer from '../reducers/ordersReducer';
import accountReducer from '../reducers/accountReducer';
import paymentReducer from '../reducers/paymentReducer';
import subscriptionReducer from '../reducers/subscriptionReducer';
import storeOverviewReducer from '../reducers/storeOverviewReducer';
// import paymentReducer from "../reducers/paymentReducer";
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import recipetReducer from '../reducers/recipetReducer';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: {
    productsReducer,
    storeOverviewReducer,
    authReducer,
    quotesReducer,
    ordersReducer,
    accountReducer,
    paymentReducer,
    subscriptionReducer,
    recipetReducer,
  },
  middleware: customizedMiddleware,
});
