import { createSlice } from '@reduxjs/toolkit';
import storageService from '../../services/storageService';
import * as accountActionsCreator from '../actionsCreator/accountActionsCreator';

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    userResponse: null,
    loading: false,
    userError: null,
    stripeLoading: false,
    stripeResponse: null,
    stripeError: null,
  },
  reducers: {},
  extraReducers: {
    // Login
    [accountActionsCreator.read.pending]: (state) => {
      state.loading = true;
      state.userResponse = null;
      state.userError = null;
    },
    [accountActionsCreator.read.fulfilled]: (state, action) => {
      if (action.payload?.tenant?.domain) {
        storageService.setTenantInfo(
          action.payload.tenant_status,
          action.payload.tenant.id,
          action.payload.tenant.domain,
          action.payload.tenant_subscription_status
        );
      }
      state.loading = false;
      state.userResponse = action.payload;
      state.userError = null;
    },
    [accountActionsCreator.read.rejected]: (state, action) => {
      state.loading = false;
      state.userResponse = null;
      state.userError = action;
    },

    // strip acount link
    [accountActionsCreator.stripeLink.pending]: (state) => {
      state.stripeLoading = true;
      state.stripeResponse = null;
    },
    [accountActionsCreator.stripeLink.fulfilled]: (state, action) => {
      state.stripeLoading = false;
      state.stripeResponse = action.payload;
      state.stripeError = null;
    },
    [accountActionsCreator.stripeLink.rejected]: (state, action) => {
      state.stripeLoading = false;
      state.stripeResponse = null;
      state.stripeError = action;
    },

    // reset state

    [accountActionsCreator.clearProfile]: (state) => {
      state.userResponse = null;
      state.loading = false;
      state.userError = null;
      state.stripeLoading = false;
      state.stripeResponse = null;
      state.stripeError = null;
    },
  },
});

export default accountSlice.reducer;
