/* eslint-disable import/no-unused-modules */
import _ from 'lodash';

export const apiUrls = {
  signUpVendor: () => `/api/market/vendor/write/`,
  loginUser: () => `/api/token/`,
  loginOTP: () => `api/otp_login/`,
  getSubCategories: () => `/api/market/component-subcategory/`,
  getApplication: () => `/api/market/application/`,
  getModels: () => `/api/market/model/read/`,
  addComponents: () => `/api/market/component/write/`,
  addCombinedProducts: () => `/api/combined-products/`,
  patchComponent: (id) => `/api/market/component/write/${id}/`,
  patchCombinedProducts: (id) => `/api/combined-products/${id}/`,
  addModels: () => `/api/market/model/write/`,
  patchModel: (id) => `/api/market/model/write/${id}/`,
  getEquipment: () => `/api/market/equipment/`,
  uploadImage: () => `/image/upload`,
  checkLogin: () => `/api/me/`,
  getQuoteById: (id) => `/api/shop/quote/${id}/`,
  getQuotes: (queryString) =>
    `/api/shop/quote/${!_.isEmpty(queryString) ? '?' + queryString : ''}`,
  getSubQuotes: (queryString) =>
    `/api/shop/subquote/${!_.isEmpty(queryString) ? '?' + queryString : ''}`,
  patchQuoteItem: (id) => `/api/shop/quote-item/${id}/`,
  getpossibleTransitions: (id) =>
    `/api/shop/subquote/${id}/possible-transitions/`,
  getQuotesPossibleTransitions: (id) =>
    `/api/shop/quote/${id}/possible-transitions/`,
  postpossibleTransitions: (id) => `/api/shop/subquote/${id}/`,
  postQuotepossibleTransitions: (id) => `/api/shop/quote/${id}/`,
  getProducts: (queryString) =>
    `/api/market/product/search/${
      !_.isEmpty(queryString) ? '?' + queryString : ''
    }`,
  getCombinedProductsSearch: (queryString) =>
    `/api/combined-product/search/${
      !_.isEmpty(queryString) ? '?' + queryString : ''
    }`,
  getCombinedProducts: (queryString) =>
    `/api/combined-products/${
      !_.isEmpty(queryString) ? '?' + queryString : ''
    }`,
  deleteModel: (id) => `/api/market/model/write/${id}/`,
  deleteCombinedProducts: (id) => `/api/combined-products/${id}/`,
  deleteQuote: (id) => `/api/shop/quote/${id}/`,
  deleteComponent: (id) => `/api/market/component/write/${id}/`,
  createSetupIntent: () => `/api/payment/create-setup-intent/`,
  getOrders: (queryString) =>
    `/api/shop/order/${!_.isEmpty(queryString) ? '?' + queryString : ''}`,
  getSubOrders: (queryString) =>
    `/api/shop/suborder/${!_.isEmpty(queryString) ? '?' + queryString : ''}`,
  patchDownPayment: (id) => `/api/shop/quote/${id}/`,
  stripeAccountLink: () => `/api/payouts/account-login-link/`,
  transferSubOrdersAmount: () => `/api/payouts/transfer-to-sub-vendor/`,
  getProduct: (id) => `/api/market/product/search/${id}/`,
  getCombinedProduct: (id) => `/api/combined-products/${id}`, // single product
  getCombinedProducts: (queryString) =>
    `/api/combined-products/${
      !_.isEmpty(queryString) ? '?' + queryString : ''
    }`, // all products
  getPaymentMethods: () => `/api/payment/list-payment-methods/`,
  createSetupIntent: () => `/api/payment/create-setup-intent/`,
  deletePaymentMethod: () => `/api/payment/delete-payment-method/`,
  getSubscriptions: () => `/api/shop/subscription-plan/read/`,
  chooseSubscriptionPlan: () => `/api/subscriptions/subscription-as-tenant/`,
  getSubscribedPlans: () => `/api/subscriptions/list-subscriptions/`,
  cancelSubscription: () => `/api/subscriptions/cancel-subscription/`,
  updatePaymentCard: () => `/api/payment/change-default-payment/`,
  updateSubscriptionPlan: () => `/api/subscriptions/update-subscription/`,
  getDefaultPaymentMethod: () => `/api/payment/get-default-payment-method/`,
  resendEmailVerification: () => `/api/resend_email_verification/`,
  emailVerify: (token) => `/api/email-verify/?token=${token}`,
  resetPassword: () => `/auth/password-reset/`,
  resetPasswordConfirm: () => `/auth/password-reset/confirm/`,
  addQuote: () => `/api/shop/quote-on-users-behalf/`,
  allUser: () => `/user/`,
  getUserById: (id) => `/user/${id}/`,
  quoteUpdate: () => `/api/shop/quote-update/`,
  getStoreDetails: (id) => `/api/tenant-asset/?tenant=${id}`,
  addStoreDetails: () => `/api/tenant-asset/`,
  patchStoreDetails: (id) => `/api/tenant-asset/${id}/`,
  getActivityLog: () => `/api/action-log/`,

  // for-recipets-page
  getQuoteMetadata: (id) => `api/shop/quote/metadata/${id}/`,
};
