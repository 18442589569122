import { lazy } from 'react';
import storageService from '../services/storageService';
import { routesPath } from '../constants/routesPath';
import { preloadComponent } from './preloadComponent.js';

// Replace standard imports with lazy imports
const ProfileContainer = lazy(
  () => import('../containers/profileContainer/AccountInfoContainer')
);
const ProductsContainer = lazy(
  () => import('../containers/productsContainer/ProductsContainer')
);
const SubscriptionContainer = lazy(
  () => import('../containers/subscriptionContainer/SubscriptionContainer')
);
const SubQuotesContainer = lazy(
  () => import('../containers/subQuotesContainer/SubQuotesContainer')
);
const EditQuotesContainer = lazy(
  () => import('../containers/editQuotesContainer/EditQuotesContainer')
);
const SubOrdersContainer = lazy(
  () => import('../containers/subOrdersContainer/SubOrdersContainer')
);
const PaymentContainer = lazy(
  () => import('../containers/paymentContainer/PaymentContainer')
);
const QuotesContainer = lazy(
  () => import('../containers/quotesContainer/QuotesContainer')
);
const TenantQuotesContainer = lazy(
  () => import('../containers/tenantQuotesContainer/TenantQuotesContainer')
);
const TenantOrdersContainer = lazy(
  () => import('../containers/tenantOrdersContainer/TenantOrdersContainer')
);
const TenantProductsContainer = lazy(
  () => import('../containers/tenantProductsContainer/TenantProductsContainer')
);
const OrdersContainer = lazy(
  () => import('../containers/ordersContainer/OrdersContainer')
);
const AddProductsContainer = lazy(
  () => import('../containers/addProductsContainer/AddProductsContainer')
);
const AddQuotesContainer = lazy(
  () => import('../containers/addQuotesContainer/addQuotesContainer')
);
const InventoryContainer = lazy(
  () => import('../containers/inventoryContainer/InventoryContainer')
);
const Error404Container = lazy(
  () => import('../containers/error404Container/Error404Container')
);
const RecipetContainer = lazy(
  () => import('../containers/RecieptContainer/RecieptContainer')
);
const StoreOverviewContainer = lazy(
  () => import('../containers/StoreOverviewContainer/StoreOverviewContainer')
);
const SalesAndMarketing = lazy(
  () => import('../containers/SalesAndMarketingContainer/SalesAndMarketing')
);

// Function to preload a list of routes
const preloadRoutes = (routes) => {
  routes.forEach((route) => {
    preloadComponent(route.component);
  });
};

const getAllowedRoutes = () => {
  let commonRoutes = [
    {
      exact: true,
      path: routesPath.profile,
      component: ProfileContainer,
    },
    {
      exact: true,
      path: routesPath.products,
      component: ProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addProducts,
      component: AddProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addProducts,
      component: AddProductsContainer,
    },
    {
      exact: true,
      path: routesPath.addQuotes,
      component: AddQuotesContainer,
    },

    {
      exact: true,
      path: routesPath.inventory,
      component: InventoryContainer,
    },

    {
      exact: true,
      path: routesPath.editProduct(':id'),
      component: AddProductsContainer,
    },
    {
      exact: true,
      path: routesPath.recipet(':id'),
      component: RecipetContainer,
    },
  ];

  if (storageService.getUserRole() === 'VENDOR') {
    const vendorRoutes = [
      {
        exact: true,
        path: routesPath.subQuotes,
        component: SubQuotesContainer,
      }, // {
      //   exact: true,
      //   path: routesPath.editQuote,
      //   component: EditQuotesContainer,
      // },
      {
        exact: true,
        path: routesPath.subOrders,
        component: SubOrdersContainer,
      },
      { exact: true, path: routesPath.payments, component: PaymentContainer },
      {
        exact: true,
        path: routesPath.subscription,
        component: SubscriptionContainer,
      },
    ];

    commonRoutes = [...commonRoutes, ...vendorRoutes];
  }
  if (storageService.getUserRole() === 'ADMIN') {
    const adminRoutes = [
      {
        exact: true,
        path: routesPath.quotes,
        component: QuotesContainer,
      },
      {
        exact: true,
        path: routesPath.orders,
        component: OrdersContainer,
      },
      {
        exact: true,
        path: routesPath.adminSubQuotes(':id'),
        component: SubQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.adminEditQuote(':id'),
        component: EditQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.adminSubOrders(':id'),
        component: SubOrdersContainer,
      },
    ];

    commonRoutes = [...commonRoutes, ...adminRoutes];
  }
  if (storageService.getTenantId()) {
    const tenantRoutes = [
      {
        exact: true,
        path: routesPath.tenantQuotes,
        component: TenantQuotesContainer,
      },
      {
        exact: true,
        path: routesPath.tenantOrders,
        component: TenantOrdersContainer,
      },
      {
        exact: true,
        path: routesPath.tenantProduct,
        component: TenantProductsContainer,
      },
      {
        exact: true,
        path: routesPath.storeOverview,
        component: StoreOverviewContainer,
      },
      {
        exact: true,
        path: routesPath.salesAndMarketing,
        component: SalesAndMarketing,
      },
    ];
    commonRoutes = [...commonRoutes, ...tenantRoutes];
  }
  commonRoutes.push({
    exact: true,
    path: routesPath.notFound,
    component: Error404Container,
  });

  return commonRoutes;
};

export { getAllowedRoutes, preloadRoutes };
