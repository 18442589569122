import React from 'react';
export const SubscriptionIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 13 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.241 0.228516C3.20524 0.228516 1.64323 2.06738 1.99235 4.0896C2.03654 4.34546 2.27982 4.51587 2.53397 4.4718C2.7891 4.42773 2.96024 4.1853 2.91618 3.93018C2.66654 2.48364 3.78239 1.16602 5.241 1.16602C6.69523 1.16602 7.81315 2.47742 7.56693 3.92444C7.52347 4.17969 7.69523 4.42175 7.95035 4.46521C8.2056 4.50879 8.44767 4.33691 8.49113 4.08167C8.83378 2.06763 7.27921 0.228516 5.241 0.228516Z"
      fill="#13214A"
    />
    <path
      d="M10.23 7.74838C9.87609 7.05405 9.036 6.78671 8.35497 7.12338C8.03393 6.4935 7.30053 6.20199 6.64123 6.43088V3.56052C6.64123 2.79538 6.02636 2.1561 5.27038 2.13534C4.47826 2.11288 3.82189 2.74728 3.82189 3.54111V10.7624L3.27123 10.1858C2.74267 9.63242 1.85766 9.59347 1.29821 10.0992C0.707877 10.6328 0.678458 11.5414 1.22204 12.1124L4.38683 15.488C4.82824 15.9586 5.45141 16.2286 6.09667 16.2286H9.91369C11.206 16.2286 12.2574 15.1772 12.2574 13.8849V9.00986C12.2574 7.96823 11.1587 7.28915 10.23 7.74838Z"
      fill="#13214A"
    />
  </svg>
);
