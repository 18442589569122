import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls } from '../../constants/apiUrls';
import APIs from '../../services/httpService';

const getQuoteMetadata = createAsyncThunk(
  'recipet/getQuoteMetadata',
  async (id, { rejectWithValue }) => {
    const data = {};
    data.data = data;
    data.path = apiUrls.getQuoteMetadata(id);
    try {
      const response = await APIs.fetch(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export { getQuoteMetadata };
