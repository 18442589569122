/* eslint-disable import/no-unused-modules */
const cloudinaryPath = 'https://res.cloudinary.com/botics-ai/';
export const cloudinaryBaseApi = `https://api.cloudinary.com/v1_1/botics-ai`;
export const baseUrl = `https://api.botics.dev`;
// export const baseUrl = `http://192.168.1.6:8000`;

// export const baseUrl = `http://localhost:8000`;

export const asset = (name) => {
  return cloudinaryPath + name;
};
