import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '../constants/baseUrl';
import Storage from '../services/storageService';

const API = axios.create({
  baseURL: baseUrl,
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const expectedError =
      error.response?.status >= 400 && error.response?.status < 500;

    if (!expectedError) {
      toast.error('An unexpected error occurrred.');
    }

    if (error.response?.status === 401) {
      Storage.clearJWTToken();
      window.location = '/login';
    }

    return Promise.reject(error);
  }
);

const fetch = async ({ path, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    const res = await API.get(path, { headers });
    return res;
  } catch (ex) {
    throw ex;
  }
};

const post = async ({ path, data, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.post(path, data, { headers });
  } catch (ex) {
    throw ex;
  }
};

const put = async ({ path, data, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.put(path, data, { headers });
  } catch (ex) {
    throw ex;
  }
};

const dell = async ({ path, csrf }) => {
  try {
    let headers = {};
    if (csrf) headers = { ...csrf };
    return await API.delete(path, { headers });
  } catch (ex) {
    throw ex;
  }
};

const patch = async ({ path, data, csrf }) => {
  let headers = {};
  if (csrf) headers = { ...csrf };
  return await API.patch(path, data, { headers });
};

const patchWithToast = async ({ path, data, csrf }) => {
  let headers = {};
  if (csrf) headers = { ...csrf };

  // Display a 'pending' toast
  const toastId = toast('Updating data...', {
    autoClose: false,
    style: {
      background: '#13214a',
      color: '#fff',
    },
  });
  try {
    await API.patch(path, data, { headers });
    toast.update(toastId, {
      render: 'Data updated successfully!',
      type: 'success',
      autoClose: 5000,
      style: {
        background: '',
        color: '',
      },
    });
  } catch (error) {
    toast.update(toastId, {
      render: 'Failed to update data.',
      type: 'error',
      autoClose: 5000,
      style: {
        background: '',
        color: '',
      },
    });
  }
};

export default {
  post,
  dell,
  fetch,
  patch,
  put,
  patchWithToast,
};
