import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { Collapse, Navbar, Row, Col } from 'reactstrap';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as authActionsCreator from '../../redux/actionsCreator/authActionsCreator';
import * as productActionsCreator from '../../redux/actionsCreator/productActionsCreator';
import { routesPath } from '../../constants/routesPath';
import Storage from '../../services/storageService';
import storageService from '../../services/storageService';

import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';
import profileImage from '../../assets/images/profile-btn-img.png';
import dottedMenuButton from '../../assets/images/dotted-menu-btn.svg';
import togglerIcon from '../../assets/images/toggler.svg';
import './nav.css';

export const AdminNavbar = () => {
  const [collapseOpen, toggleCollapseOpen] = useState(false);
  const user = useSelector((state) => state.accountReducer.userResponse);

  const dispatch = useDispatch();
  const history = useHistory();

  const [menu, setMenu] = useState(null);
  const [menuMb, setMenuMb] = useState(null);
  const prevMenu = useRef(menu);
  const prevMenuMb = useRef(menuMb);
  const prevMenuRef = useRef(null);
  const prevMenuMbRef = useRef(null);

  useEffect(() => {
    if (prevMenu.current === true && menu === false) {
      prevMenuRef.current.focus();
    }
    prevMenuRef.current = menu;
  }, [menu]);

  useEffect(() => {
    if (prevMenuMb.current === true && menuMb === false) {
      prevMenuMbRef.current.focus();
    }

    prevMenuMbRef.current = menuMb;
  }, [menuMb]);

  const handleMenu = (e) => {
    if (menu) {
      setMenu(false);
    } else {
      setMenu(e.currentTarget);
    }
  };

  const handleMenuMb = (e) => {
    if (menuMb) {
      setMenuMb(false);
    } else {
      setMenuMb(e.currentTarget);
    }
  };

  const authHandler = () => {
    storageService.clearStorage();
    dispatch(authActionsCreator.logOut());
    history.push(routesPath.login);
    setMenu(null);
    setMenuMb(null);
  };

  return (
    <>
      <div
        // style={{ position: "fixed", width: "100%", zIndex: "999", top: "0" }}
        style={{ position: 'fixed', width: '100%', zIndex: '1000', top: '0' }}
        className={Storage.getJWTToken() ? 'wrapper-dash' : ''}
      >
        <div className="row" style={{ zIndex: '999' }}>
          <div className="col-lg-12  mx-0">
            <Navbar className={'logedin-nav'} expand="lg">
              <div
                className={
                  Storage.getJWTToken() ? 'container-fluid' : 'container'
                }
              >
                <NavbarBrand
                  className={
                    window.location.pathname.includes(routesPath.dashboard)
                      ? 'profile-brand'
                      : ''
                  }
                >
                  <img
                    onClick={() => history.push(routesPath.ecommerce)}
                    src={logo}
                    srcSet={`${logo2x} 2x`}
                    className="img-fluid"
                  />
                </NavbarBrand>
                <button
                  className={
                    Storage.getJWTToken()
                      ? 'navbar-toggler d-block config-login-toggler'
                      : 'navbar-toggler d-none'
                  }
                  onClick={() => {
                    dispatch(productActionsCreator.drawerHandler());
                  }}
                >
                  <img src={togglerIcon} className="config-login-toggler" />
                </button>
                <button
                  className="navbar-toggler order-last"
                  onClick={() => toggleCollapseOpen(!collapseOpen)}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <Collapse isOpen={collapseOpen} navbar className="m-auto ">
                  <Row className="d-none">
                    <Col className="collapse-close" xs="6">
                      <button
                        className="navbar-toggler"
                        onClick={() => toggleCollapseOpen(!collapseOpen)}
                      ></button>
                    </Col>
                  </Row>
                </Collapse>
                {Storage.getJWTToken() && (
                  <div className="profile-btn-wrap-wrapper">
                    <div
                      className="profile-btn-wrap d-none d-md-block"
                      aria-controls="profile-menu"
                      aria-haspopup="true"
                      onClick={handleMenu}
                    >
                      <a className="profile-btn">
                        <img
                          src={
                            user?.profile_images
                              ? user?.profile_images
                              : profileImage
                          }
                          alt="profile icon"
                        />
                      </a>
                    </div>
                    <Menu
                      style={{ zIndex: '1400' }}
                      onClose={() => {
                        setMenuMb(null);
                        setMenu(null);
                      }}
                      className={
                        window.innerWidth <= 768
                          ? 'profile-select-menu-mb'
                          : 'profile-select-menu'
                      }
                      id="profile-menu"
                      keepMounted
                      open={Boolean(menu)}
                      anchorEl={menu}
                    >
                      <MenuItem
                        onClick={() => {
                          setMenu(null);
                          setMenuMb(null);
                          history.push(routesPath.profile);
                        }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem onClick={authHandler}>
                        {Storage.getJWTToken() ? 'Logout' : 'Login'}
                      </MenuItem>
                    </Menu>
                  </div>
                )}
                {Storage.getJWTToken() && (
                  <div
                    className="profile-cart-dropdown d-block d-md-none order-last"
                    onClick={(e) => handleMenuMb(e)}
                    aria-controls="profile-menu-mb"
                    aria-haspopup="true"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="toggle-profile-cart">
                      <img
                        src={dottedMenuButton}
                        alt="show cart and profile button"
                      />
                    </i>
                  </div>
                )}
                <Menu
                  className="profile-select-menu-mb-top"
                  id="profile-menu-mb"
                  keepMounted
                  open={Boolean(menuMb)}
                  anchorEl={menuMb}
                  onClick={() => {
                    // setMenuMb(null);
                    // setMenu(null);
                  }}
                  onClose={() => {
                    setMenuMb(null);
                    setMenu(null);
                  }}
                >
                  <MenuItem
                    aria-controls={'profile-menu'}
                    aria-haspopup="true"
                    onClick={(e) => {
                      // setMenuMb(null);
                      handleMenu(e);
                    }}
                  >
                    <div className="profile-btn-wrap">
                      <a className="profile-btn">
                        <img src={profileImage} alt="profile icon" />
                      </a>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};
